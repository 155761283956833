<template>
  <div class="patentList fatherHeight contBox" style="display: none;">
    <div id="piaoju">
      <div class="hiprint-printTemplate" v-for="(pageItem,index1) in pageData" :key="index1">
        <div class="hiprint-printPanel panel-index-0" :id="'panelRK'+index1">
          <div class="hiprint-printPaper" original-height="297"
            style="width: 210mm; height: 297mm;background-color: bisque;">
            <div class="hiprint-printPaper-content">
              <div class="hiprint-printPaper-content">
                <div tabindex="1" class="hiprint-printElement hiprint-printElement-text"
                  style="position: absolute; width: 200mm; font-size: 23pt; font-weight: 600; letter-spacing: 2.5pt; color: rgb(41, 53, 227); text-align: center; line-height: 18pt;  top: 2pt;">
                  <div class="hiprint-printElement-text-content hiprint-printElement-content"
                    style="height:100%;width:100%">入库单</div>
                </div>
                <div tabindex="1" class="hiprint-printElement hiprint-printElement-text"
                  style="position: absolute; width: 250pt; height: 15pt; color: rgb(41, 53, 227); font-size: 12pt;text-align: left; line-height: 12pt; left: 20pt; top: 55pt;">
                  <div class="hiprint-printElement-text-content hiprint-printElement-content"
                    style="height:100%;width:100%">供货单位：{{info.supplier}}</div>
                </div>
                <div tabindex="1" class="hiprint-printElement hiprint-printElement-text"
                  style="position: absolute; width: 160pt; height: 15pt; color: rgb(41, 53, 227);font-size: 12pt; text-align: left; line-height: 12pt; left: 230pt; top: 55pt;">
                  <div class="hiprint-printElement-text-content hiprint-printElement-content"
                    style="height:100%;width:100%">单据号：{{info.order_no}}</div>
                </div>
                <div tabindex="1" class="hiprint-printElement hiprint-printElement-text"
                  style="position: absolute; width: 180pt; height: 15pt; color: rgb(41, 53, 227);font-size: 12pt; text-align: left; line-height: 12pt; left: 400pt; top: 55pt;">
                  <div class="hiprint-printElement-text-content hiprint-printElement-content"
                    style="height:100%;width:100%">入库日期：{{info.enter_time}}</div>
                </div>
                <div class="hiprint-printElement hiprint-printElement-text"
                  style="position: absolute; color: rgb(41, 53, 227); left: 10pt; top: 75pt;">
                  <table border="1" cellpadding="0" cellspacing="0"
                    style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 12pt;">
                    <tr
                      style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 12pt;">
                      <td width="300" rowspan="2">药品名称</td>
                      <td rowspan="2" width="80">规格</td>
                      <td rowspan="2" width="180">厂家</td>
                      <td rowspan="2" width="80">数量</td>
                      <td rowspan="2" width="60">单位</td>
                      <td rowspan="2" width="80">批号</td>
                      <td rowspan="2" width="120">有效期</td>
                      <td colspan="2">采购价</td>
                      <td colspan="2">零售价</td>
                      <td rowspan="2" width="60">进零差额</td>
                    </tr>
                    <tr
                      style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 12pt;">
                      <td width="60">单价</td>
                      <td width="60">总额</td>
                      <td width="60">单价</td>
                      <td width="60">总额</td>
                    </tr>
                    <tr v-for="(item,index) in pageItem" :key="index"
                      style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 10pt;">
                      <td>{{item.drug.name}}</td>
                      <td>{{item.drug.medi_spec}}</td>
                      <td width="200">{{item.drug.company}} </td>
                      <td>{{item.enter_num}}{{ item.drug.min_package_unit_name }}
                        <span v-if="item.enter_sub_num">{{ item.enter_sub_num }} {{
                          item.drug.min_unit_name }}</span>
                      </td>
                      <td>{{item.drug.dict_first_unit_name}}</td>
                      <td>{{item.batch_no}}</td>
                      <td>{{item.validity_end_time}}</td>
                      <td>{{item.buy_price}}</td>
                      <td>{{item.total_buy_price}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.total_sell_price}}</td>
                      <td>{{(item.total_sell_price - item.total_buy_price).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td colspan="6">备注：</td>
                      <td>合计</td>
                      <td colspan="2">{{pageItem[0].buyPrice.toFixed(2)}}</td>
                      <td colspan="2">{{pageItem[0].Price.toFixed(2)}}</td>
                      <td>{{pageItem[0].total.toFixed(2)}}</td>
                    </tr>
                  </table>
                  <div
                    style="display: flex;justify-content: space-between;align-items: center; width: 200mm;margin-top: 5pt;">
                    <div
                      style=" width: 340pt; height: 15pt; color: rgb(41, 53, 227); text-align: left; line-height: 12pt;font-size: 12pt;">
                      主任：</div>
                    <div
                      style="width: 340pt; height: 15pt; color: rgb(41, 53, 227); text-align: left; line-height: 12pt;font-size: 12pt;left: 150pt;top:15pt">
                      保管：</div>
                    <div
                      style="width: 340pt; height: 15pt; color: rgb(41, 53, 227); text-align: left; line-height: 12pt;font-size: 12pt;left: 340pt;top:15pt">
                      采购员：</div>
                    <div
                      style="width: 340pt; height: 15pt; color: rgb(41, 53, 227); text-align: left; line-height: 12pt;font-size: 12pt;left: 340pt;top:15pt">
                      会计：</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted, defineExpose } from 'vue';
  const info = ref('')
  const pageData = ref([])
  const tableData = ref([])
  const page = 1
  const pageSize = 20
  const initData = (item) => {
    info.value = ''
    tableData.value = []
    pageData.value = []
    info.value = item
    tableData.value = JSON.parse(JSON.stringify(info.value.drug_list))
    for (let i = 0; i < Math.ceil(tableData.value.length / pageSize); i++) {
      pageData.value[i] = tableData.value.slice(i * pageSize, (i + 1) * pageSize)
    }
    pageData.value.forEach((item, index) => {

      item.forEach(ite => {
        ite.buyPrice = item.reduce((pre, cur) => {
          return Number(pre) + Number(cur.total_buy_price)
        }, 0)
        ite.Price = item.reduce((pre, cur) => {
          return Number(pre) + Number(cur.total_sell_price)
        }, 0)
        ite.total = item.reduce((pre, cur) => {
          return Number(pre) + (Number(cur.total_sell_price) - Number(cur.total_buy_price))
        }, 0)
      })
      console.log(item)
    })
  }
  //A4的panel设置'orient': 1
  const a4Panel = { width: 210, height: 297, paperFooter: 20, paperHeader: 40, paperNumberDisabled: true, }
  import { autoConnect, disAutoConnect, hiprint, defaultElementTypeProvider } from 'vue-plugin-hiprint'
  // disAutoConnect()// 取消自动连接直接打印客户端
  hiprint.init()//初始化
  const print = (title) => {
    let hiprintTemplate = new hiprint.PrintTemplate();
    pageData.value.forEach((item, index) => {
      let panel = hiprintTemplate.addPrintPanel(a4Panel);
      panel.addPrintHtml({ options: { width: 210, height: 297, top: 40, left: 10, content: $('#panelRK' + index).html() } });
    })
    hiprintTemplate.print();
  }
  defineExpose({
    print,
    initData
  })
</script>
<style scoped>
  .patentList {
    background-color: #fff;
  }
</style>