<template>
  <!-- 出库弹窗统计 -->
  <div>
    <BaseDialog title="入库列表" :isshow="visible" @handleShow="
    (val) => {
      visible = val
    }
  " width="1100px" height="auto">
      <!-- 内容-->
      <div style="padding-top: 20px;">
        <div style="display: flex;justify-content: space-between;">
          <el-form :inline="true" :model="formDataRKLB" ref="formDataRKLBRef" class="demo-form-inline form-two">
            <el-form-item label="日期:">
              <el-date-picker v-model="formDataRKLB.time" @change="formDataRKLBSearch" type="daterange"
                range-separator="至" value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="类型:">
              <el-select clearable @change="typeRKLB" placeholder="选择种类" v-model="formDataRKLB.dict_type">
                <el-option key="" label="全部" value=""></el-option>
                <el-option v-for="item in dictList[164000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input class="w-208" @input="searchRKLB" @keydown.enter="searchRKLB" v-model="formDataRKLB.order_no"
                clearable placeholder="名称/拼音">
                <template #suffix>
                  <div @click="searchRKLB" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <!-- <el-button class="color-grey" @click="print()">
            <i class="fa fa-print"></i>
            打印
          </el-button> -->
        </div>
        <div>
          <el-table class="sss" v-loading="dataListLoading" :data="tableData" stripe border max-height="56vh">
            <el-table-column width="180" prop="name" label="名称" fixed show-overflow-tooltip>
              <template #default="scope">
                <span>{{ scope.row.drug.name }}</span>
              </template>
            </el-table-column>
            <el-table-column width="130" prop="order_no" fixed label="入库单号" />
            <el-table-column width="100" prop="batch_no" label="批号" fixed show-overflow-tooltip />
            <el-table-column width="150" property="drug.medi_spec" label="规格" show-overflow-tooltip />
            <el-table-column width="120" property="drug.approval_no" label="国药准字" show-overflow-tooltip />
            <el-table-column width="180" property="drug.company" label="厂家" show-overflow-tooltip />
            <el-table-column width="100" label="入库数量">
              <template #default="scope">
                <span>{{ scope.row.enter_num }}</span>
              </template>
            </el-table-column>
            <el-table-column width="80" label="单位">
              <template #default="scope">
                <span>{{ scope.row.drug.dict_first_unit_name }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="validity_end_time" label="到期时间" show-overflow-tooltip />
            <!-- 入库单价 -->
            <el-table-column label="采购单价" width="100">
              <template #default="scope">
                <span>{{ scope.row.buy_price }}元</span>
              </template>
            </el-table-column>
            <!-- 入库总额 -->
            <el-table-column label="采购进价总额" width="130">
              <template #default="scope">
                <span>{{ (scope.row.buy_price * scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column>
            <!-- <el-table-column width="100" label="零售单价">
              <template #default="scope">
                <span>{{ scope.row.price }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="120" label="零售单价总额">
              <template #default="scope">
                <span>{{ (scope.row.price * scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column>
            <el-table-column width="100" label="进零差额">
              <template #default="scope">
                <span>{{ (scope.row.price * scope.row.enter_num - scope.row.buy_price *
                  scope.row.enter_num).toFixed(2) }}元</span>
              </template>
            </el-table-column> -->
            <el-table-column width="180" prop="enter_time" label="入库时间" />
            <el-table-column width="80" property="operator.name" label="入库人" align="center" />
            <el-table-column prop="remark" label="备注" width="180" />
            <el-table-column fixed="right" label="操作" width="60">
              <template #default="scope">
                <el-popconfirm title="是否确定退货?" @confirm="stockReturn(scope.row)">
                  <template #reference>
                    <el-button type="text" size="small" style="color:red">
                      退货
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum" @size-change="handleSizeChange"
              @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>
      </div>
      <el-divider />
      <div style="text-align: right">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="visible = false">确定</el-button>
      </div>
    </BaseDialog>
    <div>
      <BaseDialog title="退货" :isshow="puncture" @handleShow="(val) => {
        puncture = val
      }" width="700px" height="auto">
        <div class="addform">
          <el-form :inline="true" :model="ruleFormAlert" ref="ruleFormAlertRef" class="demo-form-inline w-144">
            <el-form-item label="可退 ">
              <el-input v-model="ruleFormAlert.surplus_return_num" disabled />
            </el-form-item>
            <el-form-item label="退货 ">
              <el-input v-model="ruleFormAlert.return_num" placeholder="请填写" :disabled="disabled" />
            </el-form-item>
          </el-form>
        </div>
        <el-divider />
        <div style="text-align: right">
          <el-button @click="puncture = false">取消</el-button>
          <el-button type="primary" @click="Confirmpuncture()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <printRK ref="printRKRef"></printRK>
  </div>
</template>

<script>
  import printRK from '@/views/depmtDrug/printRK.vue'
  import { toRefs, reactive, onMounted, getCurrentInstance } from 'vue'
  import service from '@/utils/request'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { gitDictList, TimeYear } from '@/utils/tool'
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
    components: {
      BaseDialog,
      printRK
    },
    setup() {
      const state = reactive({
        visible: false,
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        formDataRKLB: {
          time: '',
          dict_type: ''
        },//入库列表查询
        tableData: [],
        dictList: {},
        ruleFormAlert: {},
        puncture: false,
      })
      // 获取列表
      const tobodyAlert = async () => {
        state.visible = true
        state.loading = true
        let data = {}
        if (state.formDataRKLB.time) {
          data = {
            size: state.pageData.pageSize,
            current: state.pageData.currentPage,
            start_time: state.formDataRKLB.time[0],
            end_time: state.formDataRKLB.time[1],
            dict_type: state.formDataRKLB.dict_type,
            name: state.formDataRKLB.order_no,
            dict_catalog_type: '164000000'
          }
        } else {
          data = {
            size: state.pageData.pageSize,
            current: state.pageData.currentPage,
            dict_type: state.formDataRKLB.dict_type,
            name: state.formDataRKLB.order_no,
            dict_catalog_type: '164000000'
          }
        }
        let res = await service.post('/api/store_enter_record/get_all_enter_record_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.tableData.map(i =>{
              i.buy_price = i.buy_price * 1
              i.price = i.price  * 1
            })
            console.log(state.tableData)
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      // 获取字典
      const initData = async () => {
        state.dictList = await gitDictList([
          '332900000',
          '210000000',
          '103000000',
          '116000000',
          '176000000',
          '231000000',
          '157000000',
          '158000000',
          '159000000',
          '339600000',
          '164000000',
          '113000000',
          '161000000',
          '100000000',
          '340200000',
          '339600000',
        ])
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobodyAlert()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobodyAlert()
      }
      //入库列表时间点击事件
      const formDataRKLBSearch = (val) => {
        state.formDataRKLB.time = val
        tobodyAlert()
      }
      const typeRKLB = () => {
        console.log(state.formDataRKLB)
        tobodyAlert()
      }
      const searchRKLB = () => {
        tobodyAlert()
      }
      // 首页表格退货
      const modParient = async (row) => {
        // let data = {
        //   enter_id: row.id,
        //   dict_catalog_type:'164000000'
        // }
        // let res = await service.post('/api/stockReturn/calc_return_num', data)
        // if (res.code == 0) {
        //   state.ruleFormAlert.surplus_return_num = res.data.surplus_return_num
        //   console.log(res.data.surplus_return_num)
        // }
        state.ruleFormAlert.surplus_return_num = row.surplus_num
        state.ruleFormAlert.enter_id = row.id
        state.puncture = true
      }
      // 退货
    const stockReturn = async (row) => {
        let data = {
          enter_id: row.id,
        }
        let res = await service.post('/api/store_enter_record/return_record', data)
        if (res.code == 0) {
          ElMessage({
            message: '退货成功',
            type: 'success',
          })
          tobodyAlert()
        }
    }
      // 新增确定 退货确定
      const Confirmpuncture = async () => {
        let data = {}
        let url = ''
        //首页退货
        console.log(state.ruleFormAlert)
        data = state.ruleFormAlert
        data.dict_catalog_type = "164000000"
        let userData = JSON.parse(localStorage.getItem('ms_userData'))
        data.operator_id = userData.id
        url = '/api/stockReturn/save'
        let res = await service.post(url, data)
        if (res.code == 0) {
          ElMessage({
            message: '退货成功',
            type: 'success',
          })
          state.puncture = false
          state.ruleFormAlert = {}
        }
      }
      return {
        ...toRefs(state),
        tobodyAlert,
        handleSizeChange,
        handleCurrentChange,
        formDataRKLBSearch,
        typeRKLB,
        searchRKLB,
        initData,
        modParient,
        Confirmpuncture,
        print,
        stockReturn
      }
    },
  }
</script>

<style scoped lang="scss">
  .adviceBox {
    height: 100%;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }

  .demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .faicon {
    font-size: 19px;
    margin-right: 6px;
    cursor: pointer;
  }

  .title-top {
    align-items: center;
    display: flex;
  }

  .tabclis {
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    padding: 3px;

    .tabclis_right {
      display: flex;
      padding-top: 2px;
    }
  }

  .addform {
    .el-form {
      display: flex;
      flex-wrap: wrap;

      &:deep(.el-form-item__label) {
        width: 110px !important;
        text-align: right;
      }

      &:deep(.el-input) {
        width: 200px !important;
      }

      &:deep(.el-form-item) {
        width: 45% !important;
      }

      .mt_4 {
        &:deep(.el-input) {
          width: 100px !important;
        }
      }

      .parienttag {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .tag {
          margin-right: 8px;
          margin-bottom: 8px;
          color: #409eff;
          background-color: #ecf5ff;
          height: 20px;
          line-height: 20px;
          border-radius: 3px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }

        .tags {
          margin-right: 8px;
          margin-bottom: 8px;
          background: #409eff;
          color: #ffffff;
          border-radius: 3px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-box {
    &:deep(.el-input__inner) {
      padding-right: 15px !important;
    }
  }

  .w-144 {
    margin-top: 20px;
  }

  .stop {
    background-color: #f4a939;
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .top {
    height: 35px;
    margin: 8px 0;
  }

  .margin-set {
    padding: 10px;
    float: right;
  }

</style>