<template>
  <div>
    <!-- 顶部搜索框 -->
    <div class="usertop">
      <!-- 左侧搜索 -->
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-date-picker v-model="formInline.time" type="daterange" range-separator="至" start-placeholder="开始时间"
              end-placeholder="结束时间" value-format="YYYY-MM-DD" @change="timeChange" />
          </el-form-item>
          <el-form-item>
            <el-input class="w-208" @input="search" v-model="formInline.drug_name" @keydown.enter="search" clearable
              placeholder="名称/拼音">
              <template #suffix>
                <div @click="search" class="searchcss">
                  <i class="fa fa-search"></i>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧按钮 -->
      <div>
        <el-button class="color-vice" type="primary" @click="exportTable">
          <i class="fa fa-arrow-down"></i>导出
        </el-button>
      </div>
    </div>

    <!-- 表格内容 -->
    <div>
      <el-table class="sss" v-loading="loading" ref="multipleTableRef" :data="tableData" style="width: 100%" max-height="56vh" border
        @selection-change="handleSelectionChange">
        <el-table-column width="180" prop="name" label="名称" fixed show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.drug.name }}</span>
          </template>
        </el-table-column>
        <el-table-column width="130" prop="order_no" fixed label="入库单号" />
        <el-table-column width="100" prop="batch_no" label="批号" fixed show-overflow-tooltip />
        <el-table-column width="150" property="drug.medi_spec" label="规格" show-overflow-tooltip />
        <el-table-column width="120" property="drug.approval_no" label="国药准字" show-overflow-tooltip />
        <el-table-column width="180" property="drug.company" label="厂家" show-overflow-tooltip />
        <!-- <el-table-column property="out_num" label="退库数量" width="100" /> -->
        <el-table-column width="80" label="单位">
          <template #default="scope">
            <span>{{ scope.row.drug.dict_first_unit_name }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="validity_end_time" label="到期时间" show-overflow-tooltip />
        <el-table-column property="out_time" width="180" label="出库时间"  />
        <el-table-column property="operator.name"  label="出库人"  />
        <el-table-column property="return_time" width="180" label="退库时间"  />
        <el-table-column property="returnUser.name" label="退库人"  />
        <el-table-column property="remark" label="备注"  />
      </el-table>
      <div class="pagination-box">
        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum" @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import service from '@/utils/request'
import { gitDictList, TimeYear, downLoadXlsx, TimestampToTime } from '@/utils/tool'
export default {
  setup() {
    const state = reactive({
      formInline: {
        time: '',
        drug_name: ''
      },
      loading: true,
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
    })
    onMounted(() => {
      tobody()
    })
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {}
      if (state.formInline.time) {
        data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          start_time: state.formInline.time[0],
          end_time: state.formInline.time[1],
          drug_name: state.formInline.drug_name,
          dict_catalog_type: '164000000'
        }
      } else {
        data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          drug_name: state.formInline.drug_name,
          dict_catalog_type: '164000000'
        }
      }
      let res = await service.post('/api/store_out_record/out_store_list', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.tableData = res.data.records
          state.pageData.totalSum = res.data.total
        } else {
          state.tableData = []
          state.pageData.totalSum = 0
        }
      }
      state.loading = false
    }
    // 条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    // 查询
    const search = () => {
      tobody()
    }
    const timeChange = (val) => {
      tobody()
    }
    // 导出
    const exportTable = (row) => {
      let data = {}
      if (state.formInline.time) {
        data = {
          start_time: state.formInline.time[0],
          end_time: state.formInline.time[1],
          drug_name: state.formInline.drug_name,
          dict_catalog_type: '164000000'
        }
      } else {
        data = {
          drug_name: state.formInline.drug_name,
          dict_catalog_type: '164000000'
        }
      }
      let time = TimestampToTime()
      let title = `科室耗材退库${time}.xlsx`
      downLoadXlsx('/api/stockOut/export_out_store', data, title)
    }
    return {
      ...toRefs(state),
      tobody,
      handleSizeChange,
      handleCurrentChange,
      search,
      timeChange,
      exportTable
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
}

.usertop {
  margin-bottom: 8px;
  height: 35px;
  display: flex;
  justify-content: space-between;

  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
}

.tabctext {
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  color: rgba(49, 102, 174, 1);
}

</style>