<template>
  <div class="fatherHeight">
    <!-- 中间内容部分 -->
    <div class="bodycliss">
      <!-- 左侧 -->
      <div class="lifebody">
        <div class="flex-between">
          <div class="flex-start">
            <!-- 左侧 -->
            <el-form :inline="true" :model="formInline" class="demo-form-inline search formWidth">
              <div class="form-two">
                <el-form-item label="">
                  <el-select v-model="formInline.time" class="m-2" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="formInline.time" class="m-2" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- 姓名 -->
              <div class="form-two1">
              <el-form-item class="form-two1Input">
                <el-input style="width: 100%;" v-model="formInline.time" class="w-50 m-2" placeholder="搜索姓名/首拼">
                  <template #suffix>
                    <el-icon class="el-input__icon">
                      <search />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </div>
              <!-- 时间 -->
              <div class="form-twos1">
              <el-form-item label="" style="width: 96%;">
                <el-date-picker style="width: 100%;" v-model="formInline.time" type="daterange" range-separator="至" start-placeholder="透析日期"
                  end-placeholder="透析日期">
                </el-date-picker>
              </el-form-item></div>
            </el-form>
          </div>
        </div>
        <el-scrollbar>
          <div v-if="list.length === 0">
            <el-empty description="暂无数据！"></el-empty>
          </div>

          <div class="zy-bord" :class="[listIndex === index ? 'active' : '']" v-for="(item, index) in list" :key="index"
            @click="bodycl(index, item)">
            <div class="userinfo-item">
              <div class="userinfo-item-top flex-between">
                <div class="nameBox">
                  <div class="name">{{ item.patient_name }}姓名</div>
                </div>
              </div>
              <div class="userinfo-item-bottom">
                <div class="flex-between">
                  <div class="_item">年龄: {{ item.patient_name }}岁</div>
                  <div class="_item">
                    性别:{{ item.patient_name }}性
                  </div>
                </div>
                <div class="flex-between">
                  <div class="_item">住院{{ item.patient_name }}</div>
                  <div class="_item">透析方式{{ item.patient_name }}</div>
                </div>
                <div class="flex-between">
                  <div class="_item">住院{{ item.patient_name }}</div>
                  <div class="_item">透析方式{{ item.patient_name }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <!-- 右侧 -->
      <div class="righbody">
        <div>
          <el-table class="sss" v-loading="loading" ref="multipleTableRef" :data="tableData" style="width: 100%" height="500" border
            @selection-change="handleSelectionChange">
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column prop="created_time" label="名称"  show-overflow-tooltip/>
            <el-table-column prop="model_name" label="规格"  show-overflow-tooltip/>
            <el-table-column prop="function_name" label="出库数量" />
          </el-table>
          <div class="pagination-box">
            <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
              :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalSum" @size-change="handleSizeChange"
              @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { toRefs, reactive, onMounted } from 'vue'
  import service from '@/utils/request'
  export default {
    setup() {
      const state = reactive({
        formInline: {
          time: '1',
        },
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableData: [],
        list: [{
          patient_name: '1'
        }]
      })
      onMounted(() => {
        tobody()
      })
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
        }
        let res = await service.post('/api/operate/list', data)
        if (res.code === 0) {
          if (res.data.count > 0) {
            state.tableData = res.data.record
            state.pageData.totalSum = res.data.count
          } else {
            state.tableData = []
          }
        }
        state.loading = false
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobody()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobody()
      }
      // 查询
      const search = () => {
        tobody()
      }

      return {
        ...toRefs(state),
        tobody,
        handleSizeChange,
        handleCurrentChange,
        search,
      }
    },
  }
</script>

<style scoped lang="scss">
  .bodycliss {
  display: flex;
  margin-top: 2px;
  /* margin-bottom: 70px; */
  height: calc(100% - 88px);
}

.lifebody {
  width: 305px;
  /* height: 812px; */
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 8px;
}
.righbody {
  width: calc(100% - 226px);
  margin-left: 4px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 0 8px 8px 8px;
  overflow-y: scroll;
}
::v-deep.el-form--inline .el-form-item {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 14px;
}
.formWidth{
  width: 300px;
}
.lifebody::-webkit-scrollbar {
  display: none;
}

.zy-bord {
  width: calc(100% - 4px);
  margin-left: 2px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #eaeff7;
  cursor: pointer;
  .userinfo-item-top {
    height: 40px;
    align-content: center;
    background: #eaeff7;
    border-radius: 6px 6px 0px 0px;
    padding: 8px;
    .nameBox {
      display: flex;
      align-content: center;
    }
    .name {
      font-size: 16px;
      color: #000000;
      margin-right: 8px;
      border-left: 3px solid #3167a8;
      padding-left: 4px;
    }
    .bed_num {
      color: #999999;
    }
    .pcls {
      height: 22px;
      line-height: 22px;
      background: #d4deeb;
      border-radius: 2px;
      font-size: 12px;
      padding: 0 4px;
      color: #3167a8;
      cursor: pointer;
    }
  }
  
}
.form-twos{
  width: 85%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
.form-two{
  width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .form-twos .el-form-item,.el-forms .el-select{
    width: 100% !important;
  }
  .form-two1{
    width: 100%;
  }
  .form-two1Input{
    width: 96%;
  }
  .userinfo-item-bottom {
    padding: 8px;
    padding-bottom: 2px;
    ._item {
      font-size: 13px;
      color: #444444;
      margin-bottom: 6px;
    }
  }
  /* .form-two .el-form-item,.el-form .el-select{
    width: 48% !important;
  } */
</style>